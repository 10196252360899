import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, Collapse as MuiCollapse} from '@mui/material';
import {TextField} from '@ozark/common/components';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import * as yup from 'yup';
import {Props} from '.';
import {useStore} from '../../store';
import Title from '../Title';

type FormInput = {
  routingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  bankName: string;
};

const schema = yup.object().shape({
  routingNumber: yup
    .string()
    .length(9, 'Routing Number must be 9 digits')
    .required('Routing Number is required'),
  bankAccountNumber: yup.string().required('Bank Account Number is required'),
  confirmBankAccountNumber: yup
    .string()
    .oneOf([yup.ref('bankAccountNumber')], 'Bank Account Numbers do not match')
    .required('Confirmation is required'),
  bankName: yup.string().required('Bank Name is required'),
});

const transform = (onSuccess: any) => (data: any) => {
  delete data.isManualInput;
  onSuccess(data);
};

const DepositsPageBasic = ({setValidationHandler, onDirty}: Props) => {
  const {application} = useStore();
  const location = useLocation();

  const [blurAccountNumber, setBlurAccountNumber] = useState(true);
  const [blurConfirmAccountNumber, setBlurConfirmAccountNumber] = useState(true);

  const {formState, reset, control, watch, handleSubmit, trigger} = useForm<FormInput>({
    defaultValues: {
      bankAccountNumber: application.data?.bankAccountNumber,
      confirmBankAccountNumber: application.data?.confirmBankAccountNumber,
      bankName: application.data?.bankName,
      routingNumber: application.data?.routingNumber,
    },
    resolver: yupResolver(schema),
  });

  const {errors, isDirty} = formState;

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    if (!location.hash) return;

    const hash = location.hash.replace('#', '');
    if (hash === 'showValidation') {
      trigger('bankName', {shouldFocus: true});
      trigger('routingNumber', {shouldFocus: true});
      trigger('bankAccountNumber', {shouldFocus: true});
    }
  }, [location.hash, trigger]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit(
          (data: any) => {
            transform(onSuccess)(data);
            reset(data);
          },
          (errors: any) => {
            onError?.(errors);
          }
        )
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  const watchFields = watch(['bankAccountNumber', 'confirmBankAccountNumber', 'routingNumber']);

  const mask = !(watchFields[0]?.length > 1 && watchFields[0] === watchFields[2]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title h1="Bank Deposit Info" h2={'Tell us where to deposit your funds.'} />
        </Grid>

        <Grid item container direction="column" xs={12}>
          <MuiCollapse in={true} style={{width: '100%'}}>
            <Grid
              item
              container
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                pt: 5,
                pb: 4,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField name="bankName" label="Bank Name" errors={errors} control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="routingNumber"
                    label="Routing Number"
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="bankAccountNumber"
                    label="Account Number"
                    onFocus={() => setBlurAccountNumber(false)}
                    onBlur={() => setBlurAccountNumber(true)}
                    type={mask && blurAccountNumber ? 'password' : 'default'}
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="confirmBankAccountNumber"
                    label="Confirm Account Number"
                    onFocus={() => setBlurConfirmAccountNumber(false)}
                    onBlur={() => setBlurConfirmAccountNumber(true)}
                    type={mask && blurConfirmAccountNumber ? 'password' : 'default'}
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </MuiCollapse>
        </Grid>
      </Grid>
    </>
  );
};

export default DepositsPageBasic;
