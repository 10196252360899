import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Checkbox as MuiCheckbox,
  Typography,
} from '@mui/material';
import {LocationTypes, OwnershipTypes, ProcessingTypes, StateArray, StateHash} from '@ozark/common';
import {AutoCompleteAddressTextField, SectionTitle, TextField} from '@ozark/common/components';
import {MAX_TSYSXML_ADDRESS_FIELD_LENGTH} from '@ozark/functions/src/lib/integrations/tsys/consts';
import PhoneNumber from 'awesome-phonenumber';
import {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';
import * as yup from 'yup';
import {useStore} from '../../store';
import Select from '../Select';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  onDirty?: (isDirty: boolean) => void;
};

const stringNoExtraSpaces = (value: string) => new RegExp(/^\S.*\S$/).test(value);

const schema = yup.object().shape({
  ownershipType: yup.string().required('Business Structure is required'),
  legalBusinessName: yup
    .string()
    .required('Legal Business Name is required')
    .test('no-extra-spaces-allowed', 'Please remove leading and trailing spaces', value =>
      stringNoExtraSpaces(value ?? '')
    ),
  doingBusinessAs: yup
    .string()
    .required('DBA is required')
    .test('no-extra-spaces-allowed', 'Please remove leading and trailing spaces', value =>
      stringNoExtraSpaces(value ?? '')
    )
    .max(25, "DBA name can't be longer than 25 characters"),
  filingState: yup.string().required('Filing State is required'),
  federalTaxId: yup.string().required('Federal Tax ID is required').min(10, 'Must be a valid EIN'),
  customerServiceEmail: yup
    .string()
    .email('Customer Service Email is not valid')
    .required('Customer Service Email is required'),
  businessPhone: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Business Phone is required'),
  website: yup.string().when('processingType', (processingType: ProcessingTypes, schema: any) => {
    if (processingType === ProcessingTypes.eCommerce) {
      return schema.required('Website is required');
    }
  }),
  businessStartYear: yup
    .number()
    .min(1900, 'Must be a valid year')
    .max(new Date().getFullYear(), 'Must be a valid year')
    .typeError('Must be a valid year'),
  locationType: yup.string().required('Location Type is required'),
  businessAddress1: yup.string().required('Address is required'),
  businessAddress2: yup
    .string()
    .optional()
    .test(
      'combined-address-length',
      'Combined address lines cannot exceed 32 characters',
      function (value) {
        const {businessAddress1} = this.parent;
        return (
          (businessAddress1?.length ?? 0) + (value?.length ?? 0) <=
          MAX_TSYSXML_ADDRESS_FIELD_LENGTH - 1 // -1 for space between address lines
        );
      }
    ),
  businessCity: yup.string().required('City is required'),
  businessState: yup.string().required('State is required'),
  businessZip: yup.string().required('Zip Code is required').min(5, 'Must be a valid zip code'),
  mailingAddressDifferent: yup.boolean(),
  mailingAddress1: yup
    .string()
    .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
      if (mailingAddressDifferent) {
        return schema.required('Address is required');
      }
    }),
  mailingCity: yup
    .string()
    .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
      if (mailingAddressDifferent) {
        return schema.required('City is required');
      }
    }),
  mailingState: yup
    .string()
    .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
      if (mailingAddressDifferent) {
        return schema.required('State is required');
      }
    }),
  mailingZip: yup
    .string()
    .when('mailingAddressDifferent', (mailingAddressDifferent: boolean, schema: any) => {
      if (mailingAddressDifferent) {
        return schema.required('Zip Code is required').min(5, 'Must be a valid zip code');
      }
    }),
});

const transform = (onSuccess: any) => (data: any) => {
  onSuccess(data);
};

const BusinessPage = ({setValidationHandler, onDirty}: Props) => {
  const {application} = useStore();
  const location = useLocation();
  const {formState, reset, register, control, watch, setValue, handleSubmit, trigger} = useForm({
    defaultValues: {
      ...(application.data ?? {}),
      mailingAddressDifferent: application.data?.mailingAddressDifferent ? true : false,
    },
    shouldUnregister: true,
    resolver: yupResolver(schema),
  });

  const {errors, isDirty} = formState;

  useEffect(() => {
    register('mailingAddressDifferent');
    register('processingType');
  }, [register]);

  useEffect(() => {
    if (!location.hash || !application.data) return;

    const hash = location.hash.replace('#', '');
    if (hash === 'showValidation') {
      trigger('federalTaxId', {shouldFocus: true});
    }
  }, [location.hash, trigger, application.data]);

  //@ts-ignore TODO
  const watchMailingAddressDifferent = watch('mailingAddressDifferent');

  const handleMailingAddressDifferentChange = (event: any) => {
    setValue('mailingAddressDifferent', event.target.checked, {shouldDirty: true});
  };

  const handleAddressAutoFill =
    (addressType: 'mailing' | 'business') => (city: any, state: any, zip: any) => {
      const empty: any = '';
      setValue(`${addressType}Address2`, empty, {shouldDirty: true});
      setValue(`${addressType}City`, city ? city : empty, {shouldDirty: true});
      setValue(`${addressType}State`, state ? state : empty, {shouldDirty: true});
      setValue(`${addressType}Zip`, zip ? zip : empty, {shouldDirty: true});
    };

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          transform(onSuccess)(data);
          reset(data);
        }, onError)
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          h1="Business Profile"
          h2="Start by giving us some basic information about your business."
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="ownershipType"
          label="What's your business structure?"
          required
          errors={errors}
          control={control}
          options={Object.values(OwnershipTypes)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="legalBusinessName"
          label="Legal Business Name"
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="federalTaxId"
          label="Employer Identification Number (EIN)"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '99-9999999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="doingBusinessAs"
          label="Doing Business As (DBA)"
          errors={errors}
          control={control}
          inputProps={{maxLength: 25}}
          helperText={
            <Typography sx={{fontStyle: 'italic'}} variant="caption">
              (Your business name that your customers will see on their billing statements, 25
              maximum characters allowed)
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name="filingState"
          label="What state did you start your business in?"
          required
          errors={errors}
          control={control}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={state} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="businessStartYear"
          label="What year did you start your business?"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '9999',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          name="locationType"
          label="What best described your location type?"
          required
          errors={errors}
          control={control}
          options={Object.values(LocationTypes)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="customerServiceEmail"
          label="Customer Service Email"
          helperText={
            <Typography sx={{fontStyle: 'italic'}} variant="caption">
              (The email address where both your customers and us can reach you)
            </Typography>
          }
          defaultValue={application.data?.email}
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessPhone"
          label="Business Phone Number"
          required
          errors={errors}
          control={control}
          helperText={
            <Typography sx={{fontStyle: 'italic'}} variant="caption">
              (What your customers will see on their billing statements)
            </Typography>
          }
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      {application.data?.processingType !== ProcessingTypes.cardPresent && (
        <Grid item xs={12}>
          <TextField
            name="website"
            label="Website"
            required={application.data?.processingType === ProcessingTypes.eCommerce}
            errors={errors}
            control={control}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Physical Business Address" />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill('business')}
          name="businessAddress1"
          label="Address Line 1"
          required
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="businessAddress2"
          label="Address Line 2"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField name="businessCity" label="City" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Select name="businessState" label="State" required errors={errors} control={control}>
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={state} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          name="businessZip"
          label="Zip Code"
          required
          errors={errors}
          control={control}
          transform={{
            pattern: '99999',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={<MuiCheckbox />}
            name="mailingAddressDifferent"
            label="Mailing Address is different than Physical Address"
            checked={watchMailingAddressDifferent}
            onChange={handleMailingAddressDifferentChange}
          />
        </FormGroup>
      </Grid>
      {watchMailingAddressDifferent && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Mailing Address" />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteAddressTextField
              setAutofillHandler={handleAddressAutoFill('mailing')}
              name="mailingAddress1"
              label="Address Line 1"
              required
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="mailingAddress2"
              label="Address Line 2"
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField name="mailingCity" label="City" required errors={errors} control={control} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select name="mailingState" label="State" required errors={errors} control={control}>
              {StateArray.sortAndMap(
                state => (
                  <MenuItem key={state} value={state}>
                    {StateHash[state]}
                  </MenuItem>
                ),
                state => StateHash[state]
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="mailingZip"
              label="Zip Code"
              required
              errors={errors}
              control={control}
              transform={{
                pattern: '99999',
              }}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default BusinessPage;
