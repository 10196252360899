import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {uniq} from '@s-libs/micro-dash';
import {Controller, useFormContext} from 'react-hook-form';
import {MenuProps} from '../../../helpers';
import {PricingEquipmentFormInput} from '../PricingEquipmentSchema';

type Props = {
  targetFieldNameFull: `equipmentAdditional.${number}.accessories` | 'equipment.accessories';
  availableAccessories?: string[];
  readonly: boolean;
};

export const FieldSelectAccessories = ({
  targetFieldNameFull,
  availableAccessories,
  readonly,
}: Props) => {
  const {control, watch} = useFormContext<PricingEquipmentFormInput>();
  const classes = useStyles();

  const selectedAccessories = watch(targetFieldNameFull);

  const allAccessories = uniq([...(availableAccessories ?? []), ...(selectedAccessories ?? [])]);

  return (
    <Grid item xs={12}>
      <FormControl
        className={classes.formControl}
        fullWidth
        disabled={!availableAccessories}
        variant="outlined"
      >
        <InputLabel id="accessories-label" shrink>
          Accessories
        </InputLabel>
        <Controller
          name={targetFieldNameFull}
          control={control}
          render={({field}) => (
            <MuiSelect
              variant="outlined"
              labelId="accessories-label"
              id={targetFieldNameFull}
              name={targetFieldNameFull}
              multiple
              renderValue={selected => (selected as string[]).join(', ')}
              value={field.value || []}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              input={<OutlinedInput notched label="Accessories" />}
              MenuProps={MenuProps}
              fullWidth
              disabled={!allAccessories || readonly}
            >
              {allAccessories.length > 0 &&
                allAccessories.sortAndMap(accessoryName => (
                  <MenuItem key={accessoryName} value={accessoryName}>
                    <Checkbox
                      checked={field.value !== undefined && field.value.includes(accessoryName)}
                      color="primary"
                    />
                    <ListItemText
                      primary={
                        availableAccessories?.includes(accessoryName)
                          ? accessoryName
                          : `NOT AVAILABLE! ${accessoryName}`
                      }
                    />
                  </MenuItem>
                ))}
            </MuiSelect>
          )}
        />
      </FormControl>
    </Grid>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  })
);
