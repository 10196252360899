import {yupResolver} from '@hookform/resolvers/yup';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  TextField as MuiTextField,
  Radio,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  ApplicationType,
  CustomerReceivesProductService,
  EbtAcceptanceStatus,
  getBasicPageForAgentsValidationObjectCommon,
  getErrorMessage,
  ProcessingTypePayments,
  ProcessingTypes,
  ProductOwner,
  ProductPurchasedBy,
  RateSet,
  RefundPolicy,
  ShippingService,
  useSimpleBusinessTypes,
} from '@ozark/common';
import {Loading, RadioGroup, SectionTitle, TextField} from '@ozark/common/components';
import {MenuProps} from '@ozark/common/helpers';
import {
  BusinessTypeCodeValue,
  BusinessTypePercentReserve,
} from '@ozark/functions/src/documents/BusinessType';
import {omit} from '@s-libs/micro-dash';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useStore} from '../../store';
import {useApplicationQuery} from '../Application/hooks/useApplicationQuery';
import Select from '../Select';
import Title from '../Title';

type Props = {
  setValidationHandler(handleSubmit: any): any;
  setEndSessionHandler?(handleEndSession: any): any;
  onDirty?: (isDirty: boolean) => void;
  readonlyAccess?: boolean;
};

type BusinessPageForm = {
  businessCategory: string;
  businessType: string;
  processingType: string;
  percentSwiped: number;
  percentKeyed: number;
  percentInternet: number;
  percentTotal: string;
  percentB2b: number;
  percentInternational: number;
  percentReserve?: number | null;
  acceptPIN: boolean;
  ebtAcceptance?: EbtAcceptanceStatus;
  ebtFNS: string;
  customerReceivesProductService: string;
  refundPolicy: string;
  refundPolicyExplained: string;
  previouslyProcessed: boolean;
  estimatedMonthlyCreditCardSales: number;
  estimatedMonthlyCreditCardSalesAmex: number;
  estimatedAverageSale: number;
  estimatedHighestSale: number;
  productOwner: string;
  isCustomerDepositRequired: boolean;
  customerDepositPercentage: number;
  productPurchasedNames: string;
  productPurchasedBy: string;
  isFulfillmentCenterNamesDefined: boolean;
  fulfillmentCenterNames: string;
  isCallCenterNamesDefined: boolean;
  callCenterNames: string;
  isCbManagementSystemsNamesDefined: boolean;
  cbManagementSystemsNames: string;
  shippingServices: string[];
  otherShippingServices: string;
  rateSet: RateSet;
};

const schema = yup.object().shape({
  businessType: yup.string().required('Specific Business Type is required'),
  processingType: yup.string().required('You must select an option'),
  ...getBasicPageForAgentsValidationObjectCommon(true),
});

const transform = (onSuccess: any) => (data: any) => {
  if (data.processingType !== ProcessingTypes.cardPresent) {
    data.acceptPIN = false;
    if (data.rateSet) {
      data.rateSet.qualifiedDebitPINRate = null;
      data.rateSet.qualifiedDebitPINFee = null;
    }
  }

  if (data.processingType === ProcessingTypes.eCommerce) {
    if (!data.isCustomerDepositRequired) {
      data.customerDepositPercentage = 0;
    }
    if (!data.isFulfillmentCenterNamesDefined) {
      data.fulfillmentCenterNames = '';
    }
    if (!data.isCallCenterNamesDefined) {
      data.callCenterNames = '';
    }
    if (!data.isCbManagementSystemsNamesDefined) {
      data.cbManagementSystemsNames = '';
    }
    if (!data.shippingServices.includes(ShippingService.other)) {
      data.otherShippingServices = '';
    }
  } else {
    data.productOwner = '';
    data.customerDepositPercentage = 0;
    data.productPurchasedBy = '';
    data.productPurchasedNames = null;
    data.isFulfillmentCenterNamesDefined = false;
    data.fulfillmentCenterNames = '';
    data.isCallCenterNamesDefined = false;
    data.callCenterNames = '';
    data.isCbManagementSystemsNamesDefined = false;
    data.cbManagementSystemsNames = '';
    data.shippingServices = [];
    data.otherShippingServices = '';
  }
  onSuccess(omit(data, 'percentTotal'));
};

const BasicsPageForAgents = ({
  setValidationHandler,
  setEndSessionHandler,
  onDirty,
  readonlyAccess,
}: Props) => {
  const {application, authUser} = useStore();
  const [chosenShippingServices, setChosenShippingServices] = useState<string[]>([]);

  const {
    isSimpleBusinessTypesLoaded,
    getBusinessTypeByName,
    getBusinessTypeNames,
    getMccByBusinessType,
  } = useSimpleBusinessTypes({displayMccCodes: true});

  const {
    businessCategory,
    businessType,
    processingType,
    percentSwiped,
    percentKeyed,
    percentInternet,
    percentTotal,
    percentB2b,
    percentInternational,
    percentReserve,
    acceptPIN,
    ebtAcceptance,
    ebtFNS,
    customerReceivesProductService,
    refundPolicy,
    refundPolicyExplained,
    previouslyProcessed,
    estimatedMonthlyCreditCardSales,
    estimatedMonthlyCreditCardSalesAmex,
    estimatedAverageSale,
    estimatedHighestSale,
    productOwner,
    isCustomerDepositRequired,
    customerDepositPercentage,
    productPurchasedNames,
    productPurchasedBy,
    isFulfillmentCenterNamesDefined,
    fulfillmentCenterNames,
    isCallCenterNamesDefined,
    callCenterNames,
    isCbManagementSystemsNamesDefined,
    cbManagementSystemsNames,
    shippingServices,
    otherShippingServices,
    rateSet,
  } = application.data ?? {};

  const {formState, control, watch, reset, setValue, getValues, handleSubmit, trigger} =
    useForm<BusinessPageForm>({
      defaultValues: {
        percentSwiped: percentSwiped || 0,
        percentKeyed: percentKeyed || 0,
        percentInternet: percentInternet || 0,
        percentTotal: percentTotal || '0',
        businessCategory,
        businessType,
        processingType,
        percentB2b,
        percentInternational,
        percentReserve,
        acceptPIN,
        ebtAcceptance,
        ebtFNS,
        customerReceivesProductService,
        refundPolicy,
        refundPolicyExplained,
        previouslyProcessed,
        estimatedMonthlyCreditCardSales,
        estimatedMonthlyCreditCardSalesAmex,
        estimatedAverageSale,
        estimatedHighestSale,
        productOwner,
        isCustomerDepositRequired,
        customerDepositPercentage,
        productPurchasedNames,
        productPurchasedBy,
        isFulfillmentCenterNamesDefined: isFulfillmentCenterNamesDefined ?? true, // make the Name field required by default
        fulfillmentCenterNames,
        isCallCenterNamesDefined: isCallCenterNamesDefined ?? true,
        callCenterNames,
        isCbManagementSystemsNamesDefined: isCbManagementSystemsNamesDefined ?? true,
        cbManagementSystemsNames,
        shippingServices,
        otherShippingServices,
        rateSet,
      },
      resolver: yupResolver(schema),
    });
  const {isSummary} = useApplicationQuery();

  const {errors} = formState;

  const {isDirty, submitCount, isSubmitted} = formState;

  const watchProcessingType = watch('processingType');
  const watchBusinessType = watch('businessType');
  const watchRefundPolicy = watch('refundPolicy');
  const watchEbtAcceptance = watch('ebtAcceptance') as EbtAcceptanceStatus | undefined;
  const watchPercentSwiped = watch('percentSwiped');
  const watchPercentKeyed = watch('percentKeyed');
  const watchPercentInternet = watch('percentInternet');
  const watchIsCustomerDepositRequired = watch('isCustomerDepositRequired');
  const watchIsFulfillmentCenterNamesDefined = watch('isFulfillmentCenterNamesDefined');
  const watchIsCallCenterNamesDefined = watch('isCallCenterNamesDefined');
  const watchIsCbManagementSystemsNamesDefined = watch('isCbManagementSystemsNamesDefined');

  const watchPercentages = watch(['percentSwiped', 'percentKeyed', 'percentInternet']);

  const watchEstimatedAverageSale = watch('estimatedAverageSale');
  const watchEstimatedHighestSale = watch('estimatedHighestSale');

  const handleIsFulfillmentCenterNamesDefinedChange = (event: any) => {
    setValue('isFulfillmentCenterNamesDefined', !event.target.checked);
    if (event.target.checked) {
      setValue('fulfillmentCenterNames', '');
    }
  };
  const handleIsCallCenterNamesDefined = (event: any) => {
    setValue('isCallCenterNamesDefined', !event.target.checked);
    if (event.target.checked) {
      setValue('callCenterNames', '');
    }
  };
  const handleIsCbManagementSystemsNamesDefined = (event: any) => {
    setValue('isCbManagementSystemsNamesDefined', !event.target.checked);
    if (event.target.checked) {
      setValue('cbManagementSystemsNames', '');
    }
  };

  const handleShippingServicesChange = (event: SelectChangeEvent<string[]>) => {
    const shippingServices = event.target.value as string[];
    setChosenShippingServices(shippingServices);
    setValue('shippingServices', shippingServices, {shouldDirty: true});
  };

  useEffect(() => {
    if (rateSet?.applicationType === ApplicationType.cashDiscount) {
      setValue('acceptPIN', false);
    }
    if (
      rateSet?.applicationType === ApplicationType.surcharge ||
      rateSet?.applicationType === ApplicationType.dualPricingPassThroughFees ||
      rateSet?.applicationType === ApplicationType.dualPricingFlatRate
    ) {
      setValue('acceptPIN', true);
    }
  }, [rateSet?.applicationType, setValue]);

  useEffect(() => {
    // set shipping services:
    const curShippingServices: string[] | null =
      (control._defaultValues.shippingServices as string[]) || null;
    if (curShippingServices) {
      const selectedShippingServices = Object.entries(ShippingService)
        .filter(([, value]) => curShippingServices.includes(value))
        .map(([, value]) => value);
      setChosenShippingServices(selectedShippingServices);
    }
  }, [control]);

  useEffect(() => {
    if (!watchProcessingType) return;
    if (watchPercentSwiped > 0 || watchPercentKeyed > 0 || watchPercentInternet > 0) {
      return;
    }

    if (watchProcessingType === ProcessingTypes.cardPresent) {
      setValue('percentSwiped', 90);
      setValue('percentKeyed', 10);
      setValue('percentInternet', 0);
    }
    if (watchProcessingType === ProcessingTypes.cardNotPresent) {
      setValue('percentSwiped', 10);
      setValue('percentKeyed', 90);
      setValue('percentInternet', 0);
      setValue('acceptPIN', false);
    }
    if (watchProcessingType === ProcessingTypes.eCommerce) {
      setValue('percentSwiped', 0);
      setValue('percentKeyed', 0);
      setValue('percentInternet', 100);
      setValue('acceptPIN', false);
    }
  }, [watchProcessingType]); // eslint-disable-line

  const calculatedTotal = useMemo(
    () =>
      watchPercentages.reduce((previous, current) => {
        const percent: number = +(current || 0);
        return previous + percent;
      }, 0),
    [watchPercentages]
  );

  useEffect(() => {
    if (submitCount > 0) {
      trigger('percentTotal');
    }
  }, [calculatedTotal, submitCount, trigger]);

  useEffect(() => {
    if (isSubmitted && watchEstimatedAverageSale && watchEstimatedHighestSale) {
      trigger('estimatedHighestSale');
    }
  }, [trigger, isSubmitted, watchEstimatedAverageSale, watchEstimatedHighestSale]);

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          // ERP and Portal Agents transform, because of displayMccCodesOn this page
          const rawBusinessType = data.businessType;
          const escapedBusinessType = rawBusinessType.replace(/^\d+ - /, '');

          data.mcc =
            getMccByBusinessType(rawBusinessType) ?? getMccByBusinessType(rawBusinessType) ?? '';
          data.businessType = escapedBusinessType;

          transform(onSuccess)(data);
          reset(data);
        }, onError)
    );
  }, [setValidationHandler, handleSubmit, getMccByBusinessType]); // eslint-disable-line

  useEffect(() => {
    if (!setEndSessionHandler) return;
    setEndSessionHandler(() => () => getValues());
  }, [setEndSessionHandler]); // eslint-disable-line

  useEffect(() => {
    if (!watchBusinessType) {
      return;
    }

    const type = getBusinessTypeByName(watchBusinessType);

    if (!type || !type.code) {
      return;
    }

    const percentReserve = BusinessTypePercentReserve[type.code as BusinessTypeCodeValue] ?? null;

    setValue('percentReserve', percentReserve);
  }, [getBusinessTypeByName, setValue, watchBusinessType]);

  const displayProcessingType = (processingType: ProcessingTypes) => {
    const paymentInfo = ProcessingTypePayments[processingType];

    return (
      <FormControlLabel
        key={processingType}
        value={processingType}
        control={<Radio disabled={readonlyAccess || isSummary} />}
        label={
          <Typography>
            {paymentInfo.title} &nbsp;
            <Typography variant="caption">&bull; {paymentInfo.caption}</Typography>
          </Typography>
        }
      />
    );
  };

  if (!isSimpleBusinessTypesLoaded) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          h1="Just the Basics"
          h2="Let's collect some basic information about this business."
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="businessType"
          control={control}
          rules={{required: true}}
          defaultValue=""
          render={({field}) => (
            <Autocomplete
              id="businessType"
              sx={{width: '100%'}}
              disableClearable={!watchBusinessType}
              disabled={readonlyAccess}
              options={getBusinessTypeNames()}
              {...field}
              onChange={(_event, newInputValue) => {
                setValue('businessType', newInputValue ?? '', {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  fullWidth
                  error={Boolean(getErrorMessage(`businessType`, errors))}
                  helperText={getErrorMessage(`businessType`, errors)?.message}
                  name="businessType"
                  label="What is your specific business type?"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="How will the merchant sell to their customers?" />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          name="processingType"
          label="How will the merchant be processing payments?"
          defaultValue=""
          required
          errors={errors}
          control={control}
          disabled={isSummary || !(authUser.claims?.portal && authUser.claims?.applicationId)}
        >
          {Object.keys(ProcessingTypePayments).map(key =>
            displayProcessingType(key as ProcessingTypes)
          )}
        </RadioGroup>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          name="percentSwiped"
          label="Percent Swiped"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
          helperText={errors.percentSwiped?.message}
          type="number"
          disabled={readonlyAccess}
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          name="percentKeyed"
          label="Percent Keyed"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
          error={Boolean(errors.percentKeyed)}
          helperText={errors.percentKeyed?.message}
          type="number"
          disabled={readonlyAccess}
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          name="percentInternet"
          label="Percent Internet"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
          error={Boolean(errors.percentInternet)}
          helperText={errors.percentInternet?.message}
          disabled={readonlyAccess}
          type="number"
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          name="percentTotal"
          label="Total Percent"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          disabled={readonlyAccess}
          value={calculatedTotal}
          fullWidth
          readOnly
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span>=&nbsp;</span>
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          name="percentInternational"
          label="Percent International"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
          error={Boolean(errors.percentInternational)}
          helperText={errors.percentInternational?.message}
          type="number"
          disabled={readonlyAccess}
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          name="percentB2b"
          label="Percent B2B"
          variant="outlined"
          margin="normal"
          control={control}
          errors={errors}
          fullWidth
          error={Boolean(errors.percentB2b)}
          helperText={errors.percentB2b?.message}
          type="number"
          disabled={readonlyAccess}
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} />
      {(+(watchPercentages[0] || 0) as number) >= 70 && (
        <Fragment>
          {rateSet?.applicationType !== ApplicationType.cashDiscount && (
            <Grid item xs={12}>
              <Select
                name="acceptPIN"
                label="Will the merchant accept Pin based debit cards?"
                required
                errors={errors}
                control={control}
                disabled={readonlyAccess}
                yesOrNo
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Select
              name="ebtAcceptance"
              label="Does the merchant accept EBT cards?"
              required
              errors={errors}
              control={control}
              disabled={readonlyAccess}
              options={Object.values(EbtAcceptanceStatus)}
              skipSortOptions
            />
          </Grid>
          {watchEbtAcceptance === EbtAcceptanceStatus.Yes && (
            <Grid item xs={12}>
              <TextField
                name="ebtFNS"
                label="EBT FNS #"
                errors={errors}
                control={control}
                disabled={readonlyAccess}
                placeholder="0000000"
                transform={{
                  pattern: '9999999',
                }}
              />
            </Grid>
          )}
        </Fragment>
      )}
      <Grid item xs={12}>
        <Select
          name="previouslyProcessed"
          label="Has the merchant accepted credit cards before?"
          required
          errors={errors}
          disabled={readonlyAccess}
          control={control}
          yesOrNo
        />
      </Grid>
      {watchProcessingType && watchProcessingType !== ProcessingTypes.cardPresent && (
        <Grid item xs={12}>
          <Select
            name="customerReceivesProductService"
            label="When do customers receive their products or services?"
            required
            errors={errors}
            disabled={readonlyAccess}
            control={control}
            options={Object.values(CustomerReceivesProductService)}
            skipSortOptions
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          name="refundPolicy"
          label="What is the business' refund policy?"
          required
          errors={errors}
          disabled={readonlyAccess}
          control={control}
          options={Object.values(RefundPolicy)}
        />
      </Grid>

      {watchRefundPolicy === RefundPolicy.other && (
        <Grid item xs={12}>
          <TextField
            name="refundPolicyExplained"
            label="Refund Policy Explanation"
            required
            errors={errors}
            disabled={readonlyAccess}
            control={control}
          />
        </Grid>
      )}
      {watchProcessingType === ProcessingTypes.eCommerce && (
        <>
          <Grid item xs={12}>
            <Select
              name="productOwner"
              label="Who owns the product?"
              required
              errors={errors}
              control={control}
              options={{
                Merchant: ProductOwner.Merchant,
                'Vendor (Drop Ship Required)': ProductOwner.Vendor,
              }}
            />
          </Grid>
          <Grid
            item
            xs={
              watchIsCustomerDepositRequired && String(watchIsCustomerDepositRequired) !== 'false'
                ? 6
                : 12
            }
          >
            <Select
              name="isCustomerDepositRequired"
              label="Are customers required to provide a deposit?"
              required
              errors={errors}
              control={control}
              yesOrNo
            />
          </Grid>
          {watchIsCustomerDepositRequired && String(watchIsCustomerDepositRequired) !== 'false' && (
            <Grid item xs={6}>
              <TextField
                name="customerDepositPercentage"
                label="Deposit Percentage"
                variant="outlined"
                margin="normal"
                control={control}
                errors={errors}
                fullWidth
                type="number"
                inputProps={{min: 0, max: 100}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                transform={{
                  pattern: '999',
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              name="productPurchasedNames"
              label="List the name(s)/addresses(es) where the product is purchased"
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="productPurchasedBy"
              label="Product purchased by"
              required
              errors={errors}
              control={control}
              options={{
                Merchant: ProductPurchasedBy.Merchant,
                Vendor: ProductPurchasedBy.Vendor,
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="fulfillmentCenterNames"
              label="List the name(s) of Fulfillment Center, Contact Name, Address, and Phone # or Email Address, if any"
              required={watchIsFulfillmentCenterNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsFulfillmentCenterNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isFulfillmentCenterNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsFulfillmentCenterNamesDefined}
                onChange={handleIsFulfillmentCenterNamesDefinedChange}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="callCenterNames"
              label="List the name(s) of call center(s) providers, if any"
              required={watchIsCallCenterNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsCallCenterNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isCallCenterNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsCallCenterNamesDefined}
                onChange={handleIsCallCenterNamesDefined}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={11}>
            <TextField
              name="cbManagementSystemsNames"
              label="List the name(s) of chargeback management systems, if any"
              required={watchIsCbManagementSystemsNamesDefined}
              errors={errors}
              control={control}
              disabled={!watchIsCbManagementSystemsNamesDefined}
            />
          </Grid>
          <Grid item xs={1}>
            <FormGroup row>
              <FormControlLabel
                name="isCbManagementSystemsNamesDefined"
                sx={{mt: 2.25}}
                control={<MuiCheckbox />}
                label="N/A"
                checked={!watchIsCbManagementSystemsNamesDefined}
                onChange={handleIsCbManagementSystemsNamesDefined}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{mt: 2}} fullWidth variant="outlined">
              <InputLabel id="shippingServices-label">
                What shipping service do you use to deliver products to customers
              </InputLabel>
              <MuiSelect
                name="shippingServices"
                variant="outlined"
                labelId="shippingService-label"
                label="What shipping service do you use to deliver products to customers"
                id="shippingServices"
                required
                error={Boolean(errors.shippingServices)}
                multiple
                renderValue={selected => (selected as string[]).join(', ')}
                value={chosenShippingServices}
                onChange={handleShippingServicesChange}
                MenuProps={MenuProps}
                fullWidth
              >
                {Object.entries(ShippingService).map(([, value]) => (
                  <MenuItem key={`${value}`} value={value}>
                    <MuiCheckbox
                      checked={chosenShippingServices.indexOf(value) > -1}
                      color="primary"
                    />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </MuiSelect>
              {errors.shippingServices?.message && (
                <FormHelperText error={true}>{errors.shippingServices?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {chosenShippingServices.includes(ShippingService.other) && (
            <Grid item xs={12}>
              <TextField
                name="otherShippingServices"
                label="Other Shipping Method"
                required={chosenShippingServices.includes(ShippingService.other)}
                errors={errors}
                control={control}
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Sales Estimates" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="estimatedMonthlyCreditCardSales"
          label="VISA/MC/DS Monthly Credit Card Sales"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          inputProps={{min: 0}}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          transform={{
            toMoney: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="estimatedMonthlyCreditCardSalesAmex"
          label="AMEX Monthly Credit Card Sales"
          required
          errors={errors}
          control={control}
          inputProps={{min: 0}}
          disabled={readonlyAccess}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          transform={{
            toMoney: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="estimatedAverageSale"
          label="Average Amount Per Sale"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          inputProps={{min: 0}}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          transform={{
            toMoney: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="estimatedHighestSale"
          label="Estimated Highest Sale Amount"
          required
          errors={errors}
          control={control}
          disabled={readonlyAccess}
          inputProps={{min: 0}}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          transform={{
            toMoney: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BasicsPageForAgents;
