import {
  Box,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {AgentView, AllMIDs, MonthlyVolumeResult} from '@ozark/functions/src/shared';
import {useEffect, useState} from 'react';
import {useApiContainer, useAuthContainer, useMidsContainer, useUserInfo} from '../../..';
import {useAgents} from '../../../hooks/useAgents';
import {useGroups} from '../../../hooks/useGroups';
import {AgentSelect} from '../../Filters/AgentSelect';
import {GroupSelect} from '../../Filters/GroupSelect';
import {MidSelect} from '../../MidSelect/MidSelect';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {useQueryMonthYear} from '../common/useQueryMonthYear';
import {VolumeSummaryByCardTypePieChart} from './VolumeSummaryByCardTypePieChart';
import {VolumeSummaryByCardTypeTable} from './VolumeSummaryByCardTypeTable';
import {VolumeSummaryDetailGraph} from './VolumeSummaryDetailGraph';

export const VolumeSummaryPage = () => {
  const {year, month} = useQueryMonthYear();
  const {selectedMid, handleSelectMid, mids} = useMidsContainer();
  const {authUser} = useAuthContainer();
  const {isErpUser} = useUserInfo();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const {agents} = useAgents();
  const {groups, selectedGroup, handleSelectGroup} = useGroups(authUser);
  const [monthlyVolumeFilterType, setMonthlyVolumeFilterType] = useState<MonthlyVolumeFilterTypes>(
    MonthlyVolumeFilterTypes.Group
  );
  const api = useApiContainer();
  const [report, setReport] = useState<MonthlyVolumeResult>();
  const [loading, setLoading] = useState(true);
  const hasData = Boolean(report?.summaryByCardType?.length);

  useEffect(() => {
    if (!api || !year || !month) return;
    const status = {cancelled: false};
    setLoading(true);
    api.reports
      .getMonthlyVolumeDetail(
        `${month.toString().padStart(2, '0')}/${year}`,
        selectedMid === AllMIDs ? undefined : selectedMid,
        selectedAgent?.id,
        selectedGroup?.id
      )
      .then(report => {
        if (!status.cancelled) {
          setLoading(false);
          setReport(report);
        }
      })
      .catch(err => {
        console.error(err);
        if (!status.cancelled) {
          setLoading(false);
        }
      });
    return () => {
      status.cancelled = true;
    };
  }, [api, year, month, selectedMid, selectedGroup, selectedAgent]);

  const handleMonthlyVolumeFilterChange = (event: SelectChangeEvent) => {
    const type = event.target.value as MonthlyVolumeFilterTypes;

    if (type === MonthlyVolumeFilterTypes.Group) {
      setSelectedAgent(undefined); // clear agent selection
    } else {
      handleSelectGroup(undefined);
    }

    setMonthlyVolumeFilterType(type);
  };

  const breadcrumbs = [<Typography variant="body1">Monthly Volume Details</Typography>];

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        {isErpUser && (
          <>
            <Select
              sx={{
                width: 124,
              }}
              size="small"
              value={monthlyVolumeFilterType}
              label="Filter By"
              onChange={handleMonthlyVolumeFilterChange}
              input={<InputBase />}
            >
              <MenuItem value={MonthlyVolumeFilterTypes.Group}>Group</MenuItem>
              <MenuItem value={MonthlyVolumeFilterTypes.Agent}>Agent</MenuItem>
            </Select>
            {monthlyVolumeFilterType === MonthlyVolumeFilterTypes.Group && (
              <GroupSelect
                groups={groups}
                onGroupSelect={handleSelectGroup}
                selectedGroup={selectedGroup}
              />
            )}
            {monthlyVolumeFilterType === MonthlyVolumeFilterTypes.Agent && (
              <AgentSelect
                agents={agents}
                onAgentSelect={setSelectedAgent}
                selectedAgent={selectedAgent}
              />
            )}
          </>
        )}
        <MidSelect selectedMid={selectedMid} handleSelectMid={handleSelectMid} mids={mids} />
      </ReportingMonthTitle>

      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}

      {!loading && hasData && (
        <Paper sx={{p: 3}}>
          {report && (
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <VolumeSummaryDetailGraph monthlyVolumeResult={report}></VolumeSummaryDetailGraph>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <VolumeSummaryByCardTypeTable summaryByCardType={report.summaryByCardType} />
                </Grid>
                <Grid item xs={6}>
                  <Box height={420} p={3} pr={5}>
                    <VolumeSummaryByCardTypePieChart summaryByCardType={report.summaryByCardType} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};

enum MonthlyVolumeFilterTypes {
  Agent = 'agent',
  Group = 'group',
}
