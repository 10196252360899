import {TicketStatus} from '../../..';
import {useTicketStatus} from './useTicketStatus';

const closedStatuses = [TicketStatus.Closed, TicketStatus.Unresolved, TicketStatus.Deleted];
const commentClosedStatuses: TicketStatus[] = closedStatuses.filter(x => x !== TicketStatus.Closed);

export function useIsEditingClosed() {
  const {status} = useTicketStatus();
  const isEditingClosed = status && closedStatuses.includes(status);
  const isCommentEditingClosed = status && commentClosedStatuses.includes(status);
  return {
    isEditingClosed,
    isCommentEditingClosed,
  };
}
