import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  TextField as MuiTextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AsyncState,
  copyWithoutRef,
  getMerchantStatus,
  GroupView,
  Merchant,
  MerchantStatus,
  MerchantView,
  SearchableMerchantView,
  UnitedStatesTimeZonesShortList,
  useGroups,
  useMerchants,
} from '@ozark/common';
import {Loading, Select, TextField} from '@ozark/common/components';
import {
  emptyStringToNull,
  getMerchantsByMids,
  MenuProps,
  trimWhiteSpace,
} from '@ozark/common/helpers';
import PhoneNumber from 'awesome-phonenumber';
import {Fragment, useEffect, useState} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {getMidsForGroup} from '../../hooks';
import {useUserInfo} from '../../hooks/useUserInfo';

export interface MerchantFormModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  timeZoneId: string;
  merchantStatus: MerchantStatus;
  groupId: string;
  applicationMids: string[];
  masterMerchant?: MerchantView | null;
}

interface GroupAndUserMidsData {
  mid: string;
  title: string;
}

export const MerchantFormSchema = yup.object().shape({
  firstName: yup.string().transform(trimWhiteSpace).required('First Name is required'),
  lastName: yup.string().transform(trimWhiteSpace).required('Last Name is required'),
  groupId: yup.string().required('Group is required'),
  email: yup.string().email().required('Email is required'),
  phoneNumber: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      return value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
    })
    .required('Phone is required'),
  merchantStatus: yup.string().when(['editMode'], {
    is: (editMode: boolean) => editMode,
    then: yup.string().transform(emptyStringToNull).required('Merchant Status is required'),
  }),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
    },
    formControl: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  })
);

type Props = {
  hookForm: UseFormReturn<MerchantFormModel, MerchantFormModel>;
  isAuthUserAdmin: boolean;
  linkedMasterUid?: string;
  editMode?: boolean;
  merchant?: Merchant;
  userGroupId?: string;
  applicationMids?: string[];
};

export const MerchantForm = ({
  hookForm,
  isAuthUserAdmin,
  editMode = false,
  merchant,
  userGroupId,
  applicationMids,
}: Props) => {
  const classes = useStyles();
  const {
    formState: {errors},
    control,
    reset,
    setValue,
    watch,
  } = hookForm;
  const {isErpUser} = useUserInfo();
  const {documents: groups} = useGroups();
  const {documents: merchants} = useMerchants();
  const [groupIsEmpty, setGroupIsEmpty] = useState<boolean>(true);
  const [groupAndUserMidsData, setGroupAndUserMidsData] = useState<GroupAndUserMidsData[]>([]);
  const [availableGroups, setAvailableGroups] = useState<GroupView[]>([]);
  const [availableMasterMerchants, setAvailableMasterMerchants] = useState<MerchantView[]>([]);

  const [merchantStatus, setMerchantStatus] = useState<MerchantStatus>(
    !!merchant ? getMerchantStatus(merchant) : MerchantStatus.active
  );
  const getGroupAndUserMids = (mids: AsyncState<Map<string, string>>) => {
    const groupMidsData = mids?.data ?? new Map();
    const groupAndUserMids = Array.from(
      new Set([
        ...Array.from(groupMidsData.keys()),
        ...(watchChosenApplicationMids ?? merchant?.applicationMids ?? []),
      ])
    );
    return groupAndUserMids.map(mid => ({
      mid,
      title: groupMidsData.get(mid) ?? 'n/a',
    }));
  };
  const filterAndSetMids = (mids: AsyncState<Map<string, string>>) => {
    if (mids.promised || !mids.data) {
      setGroupAndUserMidsData([]);
      setGroupIsEmpty(true);
      return;
    }
    if (!isErpUser) {
      setGroupIsEmpty((mids?.data || new Map()).size === 0);
      setGroupAndUserMidsData(getGroupAndUserMids(mids));
      return;
    }

    const midsArray = Array.from((mids.data ?? new Map()).keys());
    getMerchantsByMids(midsArray).then(merchantsWithMids => {
      const existingMerchantMids = merchantsWithMids.flatMap(x => x.applicationMids);
      const midEntries: GroupAndUserMidsData[] = Array.from((mids.data ?? new Map()).keys()).map(
        key => ({
          mid: key,
          title: mids.data!.get(key) || '',
        })
      );
      const merchantMids = watchChosenApplicationMids ?? merchant?.applicationMids;
      const filteredMids = midEntries.filter(
        x => existingMerchantMids.indexOf(x.mid) < 0 || merchantMids?.indexOf(x.mid) > -1
      );
      setGroupIsEmpty(filteredMids.length === 0);
      setGroupAndUserMidsData(filteredMids);
    });
  };

  const filterAndSetMerchantMids = (mids: AsyncState<Map<string, string>>) => {
    if (mids.promised || !mids.data) {
      setGroupAndUserMidsData([]);
      setGroupIsEmpty(true);
      return;
    }
    setGroupIsEmpty((mids?.data || new Map()).size === 0);
    setGroupAndUserMidsData(getGroupAndUserMids(mids));
  };

  const watchGroupId: string = watch('groupId');
  const watchChosenApplicationMids = watch('applicationMids');
  const getAvailableGroups = (masterMerchant: MerchantView | undefined) => {
    return (
      (userGroupId || masterMerchant
        ? groups.data?.filter(g => g.id === userGroupId || g.id === masterMerchant?.groupId)
        : groups.data) || []
    );
  };

  useEffect(() => {
    let masterMerchant: SearchableMerchantView | undefined;
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    if (merchants.data && !merchants.promised) {
      setAvailableMasterMerchants(merchants.data.filter(x => !x.masterUid));
      if (merchant) {
        const formData = copyWithoutRef(merchant);
        if (merchant.masterUid) {
          masterMerchant = merchants.data.find(x => x.id === merchant.masterUid);
          formData.masterMerchant = masterMerchant;
        }
        timer = setTimeout(() => {
          reset(formData);
        }, 10);
      }
      const groupId = merchant?.groupId || userGroupId;
      if (groupId) {
        getMidsForGroup(
          groupId,
          masterMerchant ? filterAndSetMerchantMids : filterAndSetMids,
          masterMerchant?.applicationMids ?? applicationMids,
          true
        );
      }
    }
    setAvailableGroups(getAvailableGroups(masterMerchant));
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [merchant, reset, merchants]);

  const handleMenuItemClick = (merchantStatus: MerchantStatus) => () => {
    setMerchantStatus(merchantStatus);
  };

  const isReadonly = Boolean(!!merchant?.id && !isAuthUserAdmin);

  if (groups.promised || merchants.promised) {
    return <Loading />;
  }

  const onMasterMerchantChange = (newMerchant: MerchantView | null) => {
    setValue('applicationMids', [], {shouldDirty: true});
    setAvailableGroups(getAvailableGroups(newMerchant === null ? undefined : newMerchant));
    if (newMerchant && newMerchant.groupId) {
      if (newMerchant.groupId !== watchGroupId) {
        setValue('groupId', newMerchant.groupId, {shouldDirty: true});
      }
      getMidsForGroup(
        newMerchant.groupId,
        filterAndSetMerchantMids,
        newMerchant.applicationMids,
        true
      );
      return;
    }
    if (watchGroupId) {
      getMidsForGroup(watchGroupId, filterAndSetMids, applicationMids, true);
    } else {
      setGroupAndUserMidsData([]);
      setGroupIsEmpty(true);
    }
  };
  const onGroupChanged = (groupId: string) => {
    if (groupId === watchGroupId || groupId === null) {
      return;
    }
    getMidsForGroup(groupId, filterAndSetMids, applicationMids, true);
    if (watchGroupId != null) {
      setValue('applicationMids', [], {shouldDirty: true});
    }
  };

  const getOptionLabel = (item: MerchantView | null) => {
    return item === null ? '' : `${item.firstName} ${item.lastName} (${item.email})`;
  };

  return (
    <Fragment>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          autoFocus
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="lastName"
          label="Last Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      {editMode && (
        <Grid item xs={editMode ? 4 : 12}>
          <TextField
            name="merchantStatus"
            value={merchantStatus}
            label="Status"
            disabled={!isAuthUserAdmin}
            errors={errors}
            control={control}
            select
          >
            {Object.values(MerchantStatus).sortAndMap(status => (
              <MenuItem key={status} value={status} onClick={handleMenuItemClick(status)}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="phoneNumber"
          label="Phone Number"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="email"
          label="Email"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <Select
          name="timeZoneId"
          label="Time Zone"
          errors={errors}
          control={control}
          disabled={isReadonly}
        >
          {Object.entries(UnitedStatesTimeZonesShortList).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {isErpUser && (
        <Grid item xs={12}>
          <Controller
            name="masterMerchant"
            control={control}
            defaultValue={null}
            render={({field: {onChange, ...props}}) => (
              <Autocomplete
                sx={{
                  margin: theme => theme.spacing(2, 0, 1),
                }}
                options={availableMasterMerchants}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={getOptionLabel}
                onChange={async (event, value) => {
                  onChange(value);
                  await onMasterMerchantChange(value);
                }}
                renderInput={params => (
                  <MuiTextField {...params} label="Owner Merchant" variant="outlined" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {getOptionLabel(option)}
                  </li>
                )}
                {...props}
                disabled={editMode}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          required
          control={control}
          defaultValue={userGroupId}
          disabled={editMode}
          errors={errors}
          label="Group"
          name="groupId"
          onChangeSuccess={onGroupChanged}
        >
          {groups.data &&
            availableGroups.sortAndMap(
              group => (
                <MenuItem key={`${group.id}`} value={group.id}>
                  {group.name}
                </MenuItem>
              ),
              group => group.name
            )}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Application MIDs
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="mids-label">Application MIDs</InputLabel>
          <Controller
            control={control}
            name="applicationMids"
            render={({field: {onBlur, onChange, ref, value, name}}) => (
              <MuiSelect
                name={name}
                variant="outlined"
                labelId="applicationMids-label"
                label="Application MIDs"
                id={name}
                multiple
                disabled={isReadonly}
                renderValue={selected => (selected as string[]).join(', ')}
                value={value || []}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                MenuProps={MenuProps}
                fullWidth
              >
                {!groupIsEmpty &&
                  groupAndUserMidsData.map(({mid, title}) => (
                    <MenuItem key={mid} value={mid}>
                      <MuiCheckbox
                        checked={watchChosenApplicationMids?.indexOf(mid) > -1}
                        color="primary"
                      />
                      <ListItemText primary={`${mid} (${title})`} />
                    </MenuItem>
                  ))}
                {groupIsEmpty && (
                  <ListItemText
                    sx={{m: 1}}
                    secondary={
                      isErpUser &&
                      'Or all MIDs are already associated with another merchant accounts, please instruct the account owner to create a sub-merchant account.'
                    }
                    primary={'There are no MIDs associated with the selected group.'}
                  />
                )}
              </MuiSelect>
            )}
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};
