import {FormControlLabel, FormGroup, Checkbox as MuiCheckbox} from '@mui/material';
import React from 'react';
import {Controller} from 'react-hook-form';

interface Props {
  name: string;
  label: string | number | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  required?: boolean;
  errors: any;
  register: any;
  [others: string]: any;
}

export const Checkbox = React.forwardRef(
  ({name, label, required, errors, register, ...other}: Props, ref) => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={<MuiCheckbox />}
          {...register(name)}
          label={label}
          value={'true'}
          ref={ref}
          {...other}
        />
      </FormGroup>
    );
  }
);

type HookFormCheckboxProps = {
  name: string;
  control: any;
  label: string;
  [others: string]: any;
};

export const HookFormCheckBox = ({name, control, label, ...other}: HookFormCheckboxProps) => (
  <Controller
    control={control}
    name={name}
    render={({field}) => (
      <FormGroup row>
        <FormControlLabel
          label={label}
          control={
            <MuiCheckbox
              {...field}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.checked)
              }
              checked={field.value || false}
              {...other}
            />
          }
        />
      </FormGroup>
    )}
  />
);
