import {useState} from 'react';
import {FieldPath, FieldValues, UseFormWatch} from 'react-hook-form';
import {FieldNamesMarkedBoolean} from 'react-hook-form/dist/types/form';
import {usePasswordValidation} from './usePasswordValidation';

type UseSetPasswordProps<TFieldValues extends FieldValues = FieldValues> = {
  watch: UseFormWatch<TFieldValues>;
  passwordFieldName: FieldPath<TFieldValues>;
  passwordConfirmationFieldName: FieldPath<TFieldValues>;
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<TFieldValues>>>;
};

type UseSetPasswordReturnType = {
  showPassword: boolean;
  showConfirmation: boolean;
  displayIsValidPassword: boolean;
  displayPasswordCheckList: boolean;
  handleClickShowPassword: () => void;
  handleClickShowConfirmation: () => void;
  passwordValue: string;
  passwordConfirmationValue: string;
};

export function useSetPassword<TFieldValues extends FieldValues>({
  watch,
  passwordFieldName,
  passwordConfirmationFieldName,
  dirtyFields,
}: UseSetPasswordProps<TFieldValues>): UseSetPasswordReturnType {
  const passwordValue = watch(passwordFieldName);
  const passwordConfirmationValue = watch(passwordConfirmationFieldName);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };
  const {isValid: isValidPassword} = usePasswordValidation({
    password: passwordValue,
    confirmation: passwordConfirmationValue,
  });
  const displayIsValidPassword = (dirtyFields as any)[passwordFieldName] && isValidPassword;
  const displayPasswordCheckList =
    !displayIsValidPassword &&
    ((dirtyFields as any)[passwordFieldName] ||
      (dirtyFields as any)[passwordConfirmationFieldName]);
  return {
    showPassword,
    showConfirmation,
    displayIsValidPassword,
    displayPasswordCheckList,
    handleClickShowPassword,
    handleClickShowConfirmation,
    passwordValue,
    passwordConfirmationValue,
  };
}
