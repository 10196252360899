import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse as MuiCollapse,
  styled,
  Typography,
} from '@mui/material';
import {
  ApplicationSubCollection,
  Collections,
  FinicityAccount,
  FinicityAchDetailResponse,
  FinicityData,
  FinicityDocumentId,
  FinicityInstitutionResponse,
  Firebase,
  formatterCurrency,
  useNotification,
} from '@ozark/common';
import {TextField} from '@ozark/common/components';
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {Props} from '.';
import {useStore} from '../../store';
import Title from '../Title';

import FinicityDesktopBackgroundImage from '../../static/images/finicity-bg-desktop.jpeg';
import FinicityEmptyBackgroundImage from '../../static/images/finicity-bg-empty-mobile.jpeg';
import FinicityLogo from '../../static/images/logos/finicity-logo.png';

const ConnectButton = styled(LoadingButton)({
  color: '#fff',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 500,
  padding: '12px 32px',
  lineHeight: 1.5,
  backgroundColor: '#73BC50',
  backgroundImage: 'linear-gradient(to right, #72B77C , #73BC50)',
  borderRadius: 8,
  '&:hover': {
    backgroundImage: 'none',
    backgroundColor: '#73BC50',
  },
  '&:active': {
    backgroundImage: 'none',
    backgroundColor: '#73BC50',
  },
});

type FormInput = {
  routingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  bankName: string;
  finicityAccount: FinicityAccount;
  isManualInput: boolean;
};

const schema = yup.object().shape({
  routingNumber: yup
    .string()
    .length(9, 'Routing Number must be 9 digits')
    .required('Routing Number is required'),
  bankAccountNumber: yup.string().required('Bank Account Number is required'),
  confirmBankAccountNumber: yup
    .string()
    .when('finicityAccount', (finicityAccount: FinicityAccount, schema: any) => {
      return !!finicityAccount
        ? schema.optional()
        : schema
            .oneOf([yup.ref('bankAccountNumber')], 'Bank Account Numbers do not match')
            .required('Confirmation is required');
    }),
  bankName: yup.string().required('Bank Name is required'),
  isManualInput: yup.boolean().default(false),
  finicityAccount: yup.object().when('isManualInput', (isManualInput: boolean, schema: any) => {
    return isManualInput ? schema : schema.required();
  }),
});

const transform = (onSuccess: any) => (data: any) => {
  delete data.isManualInput;
  onSuccess(data);
};

const DepositsPageWithFinicity = ({setValidationHandler, onDirty, setLoading}: Props) => {
  const showNotification = useNotification();
  const {
    application,
    applicationId,
    group,
    preventFinicityAutoLaunchConnect,
    setPreventFinicityAutoLaunchConnect,
    apiClient,
  } = useStore();

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [generateUrl, setGenerateUrl] = useState<string | null>(null);
  const [finicityData, setFinicityData] = useState<FinicityData>();
  const [isConnectLoading, setConnectLoading] = useState<boolean>(false);
  const [blurAccountNumber, setBlurAccountNumber] = useState(true);
  const [blurConfirmAccountNumber, setBlurConfirmAccountNumber] = useState(true);

  const {formState, reset, control, setValue, watch, handleSubmit} = useForm<FormInput>({
    defaultValues: {
      bankAccountNumber: application.data?.bankAccountNumber,
      confirmBankAccountNumber: application.data?.confirmBankAccountNumber,
      bankName: application.data?.bankName,
      routingNumber: application.data?.routingNumber,
      finicityAccount: application.data?.finicityAccount,
    },
    resolver: yupResolver(schema),
  });

  const {errors, isDirty} = formState;

  useEffect(() => {
    // load finicity script only once on page load
    const script = document.createElement('script');
    script.src = 'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    // we don't want to auto launch the dialog more than once per user session
    if (preventFinicityAutoLaunchConnect) {
      return;
    }
    setPreventFinicityAutoLaunchConnect(true);
    // automatically open finicity connect after the page renders
    const timerId = setTimeout(() => {
      handleConnectFinicity();
      timerRef.current = null;
    }, 2000 /* delay auto launch by 2 seconds to give the user time to read */);

    timerRef.current = timerId;

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!generateUrl) return;
    const connect = (window as any).finicityConnect;
    if (!connect) {
      setValue('isManualInput', true);
      return;
    }
    // launching finicity connect doesn't work without being wrapped in setTimeout
    setTimeout(() => {
      connect.launch(generateUrl, {
        selector: '#connect-container',
        overlay: 'rgba(255,255,255, 0)',
        success: (_event: any) => {
          handleConnectClose();
        },
        cancel: (_event: any) => {
          handleConnectClose();
        },
      });
    }, 0 /* delay duration does not matter */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateUrl]);

  useEffect(() => {
    onDirty?.(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => {
    // subscribes to finicity subcollection to show list of accounts when available
    const unsubscribe = Firebase.firestore
      .collection(Collections.applications)
      .doc(applicationId)
      .collection(ApplicationSubCollection.finicity)
      .doc(FinicityDocumentId.connect)
      .onSnapshot(async snapshot => {
        if (!snapshot.exists) {
          return;
        }
        const finicity = snapshot.data() as FinicityData;
        setFinicityData(finicity);
      });
    return () => {
      unsubscribe();
    };
  }, [applicationId]);

  useEffect(() => {
    if (finicityData?.accounts.length === 1) {
      handleAccountChange(finicityData.accounts[0])(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finicityData?.accounts]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit(
          (data: any) => {
            transform(onSuccess)(data);
            reset(data);
          },
          (errors: any) => {
            if (errors.finicityAccount) {
              showNotification(
                'error',
                'Please select an account from the list or enter your account details manually.'
              );
            }
            onError?.(errors);
          }
        )
    );
    // eslint-disable-next-line
  }, [setValidationHandler, handleSubmit]);

  const watchFields = watch(['bankAccountNumber', 'confirmBankAccountNumber', 'routingNumber']);

  const mask = !(watchFields[0]?.length > 1 && watchFields[0] === watchFields[2]);

  const finicityAccount = watch('finicityAccount');

  const isManualInput = watch('isManualInput');

  const handleAccountChange = (account: any) => async (_event: any) => {
    setLoading?.(true);
    // kill timer if it's running
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    // prevent setting value if account is already selected
    if (account.id === finicityAccount?.accountId) {
      setLoading?.(false);
      return;
    }
    // make sure the account id is only a checking or savings account
    if (!['checking', 'savings'].includes(account.type)) {
      showNotification('error', 'Only checking and savings accounts are allowed for deposits.');
      setLoading?.(false);
      return;
    }
    // async call to get institution and ach detail so checkbox can be checked without waiting for response
    Promise.all([
      apiClient.finicity.getInstitution(account.institutionId),
      apiClient.finicity.getAchDetail({
        applicationId: applicationId as string,
        customerId: account.customerId,
        accountId: account.id,
      }),
    ])
      .then((results: [FinicityInstitutionResponse | null, FinicityAchDetailResponse | null]) => {
        const [institution, achDetail] = results;
        if (!(achDetail && institution)) {
          return;
        }
        setValue('finicityAccount', {
          customerId: account.customerId,
          accountId: account.id,
          bankName: institution.institution?.name || '',
        });
        setValue('bankAccountNumber', achDetail.realAccountNumber);
        setValue('confirmBankAccountNumber', achDetail.realAccountNumber);
        setValue('bankName', institution.institution?.name || '');
        setValue('routingNumber', achDetail.routingNumber);
      })
      .catch(_err => {
        showNotification(
          'error',
          'Please select another account or enter your account details manually.'
        );
      })
      .finally(() => {
        setLoading?.(false);
      });
  };

  const handleConnectClose = () => {
    // finicity connect must be destroyed before it can be launched again
    (window as any).finicityConnect.destroy();
    setGenerateUrl(null);
  };

  const handleConnectFinicity = async () => {
    setConnectLoading(true);
    setValue('isManualInput', false);
    try {
      const response = await apiClient.finicity.getGenerateUrl({
        applicationId: application.data!.id,
        redirectUri: window.location.href.replace('localhost:3001', 'local.luqra.com'),
        experienceId: group.data?.finicityExperienceId,
      });
      setGenerateUrl(response.link);
    } catch (err) {
      showNotification(
        'error',
        'Erroring connecting to Finicity. Please try again or enter your account details manually.'
      );
    }
    setConnectLoading(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title
            h1="Bank Deposit Info"
            h2={isManualInput ? 'Tell us where to deposit your funds.' : null}
          />
        </Grid>

        <Grid item container direction="column" xs={12}>
          <MuiCollapse
            in={!finicityData?.isConnected || finicityData?.accounts.length === 0}
            collapsedSize={100}
            style={{width: '100%', borderRadius: 8}}
          >
            <Grid
              xs={12}
              item
              container
              direction="column"
              sx={{
                height: '50vh',
                minHeight: 500,
                backgroundImage: {
                  xs: `url('${FinicityEmptyBackgroundImage}') `,
                  md: `url('${FinicityDesktopBackgroundImage}') `,
                },
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: 1,
                backgroundPosition:
                  !finicityData?.isConnected || finicityData?.accounts.length === 0
                    ? 'right center'
                    : 'right top',
              }}
            >
              <Grid item>
                <Grid xs={12} item container direction="row" sx={{height: 100}}>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      pl: 4,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img src={FinicityLogo} alt="Finicity" height={50} />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  />
                  <Grid
                    item
                    xs={4}
                    sx={{
                      pr: 4,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <ConnectButton
                      loading={isConnectLoading}
                      loadingPosition="center"
                      onClick={handleConnectFinicity}
                      sx={{
                        display:
                          !finicityData?.isConnected || finicityData?.accounts.length === 0
                            ? 'none'
                            : 'inherit',
                      }}
                    >
                      Update Connection
                    </ConnectButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item flexGrow={1}>
                <Box
                  sx={{
                    px: 4,
                    height: '100%',
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    justifyContent: ['center', null, 'flex-start', 'center', 'center'],
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h2"
                    color={'white'}
                    gutterBottom
                    sx={{
                      fontSize: {xs: '2.0em', sm: '3.0em', md: '2.5em', lg: '3.0em', xl: '3.3em'},
                    }}
                  >
                    Faster <b>Approvals</b>
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h3"
                    color={'white'}
                    gutterBottom
                    sx={{
                      fontSize: {xs: '2.0em', sm: '3.0em', md: '2.5em', lg: '3.0em', xl: '3.3em'},
                    }}
                  >
                    Faster <b>Deposits</b>
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    color={'white'}
                    gutterBottom
                    sx={{
                      fontSize: {xs: '1.0em', sm: '1.2em', md: '1.0em', lg: '1.5em', xl: '1.7em'},
                    }}
                  >
                    Secure and seamless connection between
                    <br />
                    your bank account and your merchant account.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    p: 4,
                    display: ['flex', 'flex', 'inherit'],
                    flexDirection: {xs: 'column', sm: 'column', md: 'row'},
                  }}
                >
                  <ConnectButton
                    loading={isConnectLoading}
                    loadingPosition="center"
                    onClick={handleConnectFinicity}
                  >
                    Connect Bank
                  </ConnectButton>
                  {!isManualInput && (
                    <Button
                      sx={{
                        p: 1.3,
                        ml: 2,
                        color: '#C1B8D7',
                        mt: {xs: 2, sm: 2, md: 0},
                        borderColor: '#C1B8D7',
                      }}
                      variant="outlined"
                      onClick={() => setValue('isManualInput', true)}
                    >
                      Or enter your account manually
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MuiCollapse>

          <MuiCollapse in={isManualInput} style={{width: '100%'}}>
            <Grid
              item
              container
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                pt: 5,
                pb: 4,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField name="bankName" label="Bank Name" errors={errors} control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="routingNumber"
                    label="Routing Number"
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="bankAccountNumber"
                    label="Account Number"
                    onFocus={() => setBlurAccountNumber(false)}
                    onBlur={() => setBlurAccountNumber(true)}
                    type={mask && blurAccountNumber ? 'password' : 'default'}
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="confirmBankAccountNumber"
                    label="Confirm Account Number"
                    onFocus={() => setBlurConfirmAccountNumber(false)}
                    onBlur={() => setBlurConfirmAccountNumber(true)}
                    type={mask && blurConfirmAccountNumber ? 'password' : 'default'}
                    errors={errors}
                    control={control}
                    transform={{
                      pattern: '999999999999999999999999999',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </MuiCollapse>

          {finicityData?.isConnected && finicityData?.accounts.length > 0 && (
            <Grid
              item
              container
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                pt: 5,
                pb: 4,
              }}
            >
              <Grid item>
                <Typography variant="subtitle1" component="p" gutterBottom>
                  Select your account to use for deposits:
                </Typography>
              </Grid>
              <Grid item>
                <List
                  sx={{
                    px: 2,
                    width: 400,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: 2,
                  }}
                >
                  {finicityData?.accounts?.map((account: any, index: number) => {
                    return (
                      <React.Fragment key={`account-${index}`}>
                        {index > 0 && <Divider />}
                        <ListItem
                          secondaryAction={
                            <Typography variant="body2">
                              {formatterCurrency.format(Number(account.balance))}
                            </Typography>
                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={account.id === finicityAccount?.accountId}
                              onChange={handleAccountChange(account)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': `labelId-${index}`}}
                              disabled={!['checking', 'savings'].includes(account.type)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`labelId-${index}`}
                            primary={account.accountNickname}
                            secondary={
                              !['checking', 'savings'].includes(account.type) ? (
                                <Typography variant={'caption'} color={'red'}>
                                  Invalid Deposit Account
                                </Typography>
                              ) : (
                                `Ending in ${account.accountNumberDisplay}`
                              )
                            }
                          />
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog open={!!generateUrl} onClose={handleConnectClose}>
        <DialogContent>
          <Box
            id="connect-container"
            sx={{
              '& iframe': {
                height: 755,
                width: 375,
              },
            }}
          ></Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DepositsPageWithFinicity;
