import {RateType} from '../shared';

export enum PCIFee {
  monthIncludeInsurance = 'monthInclude',
  monthExcludeInsurance = 'monthExclude',
  yearIncludeInsurance = 'yearInclude',
  yearExcludeInsurance = 'yearExclude',
  waived = 'waived',
}

export const PCIFeeWithoutWaived: PCIFee[] = Object.values(PCIFee).filter(x => x !== PCIFee.waived);

export const PCIFeeDefaultValues: {[_ in PCIFee]: number | null} = {
  [PCIFee.waived]: null,
  [PCIFee.yearIncludeInsurance]: 169,
  [PCIFee.yearExcludeInsurance]: 99,
  [PCIFee.monthIncludeInsurance]: 14,
  [PCIFee.monthExcludeInsurance]: 14,
};

// displayed values sorted in the following order
export const PCIFeeTypes: {[_ in PCIFee]: string} = {
  [PCIFee.monthIncludeInsurance]: 'Monthly includes breach insurance',
  [PCIFee.monthExcludeInsurance]: 'Monthly excludes breach insurance',
  [PCIFee.yearIncludeInsurance]: 'Yearly includes breach insurance',
  [PCIFee.yearExcludeInsurance]: 'Yearly excludes breach insurance',
  [PCIFee.waived]: 'Waived/Billed to Residuals',
};

export const PCIFeeValueToString = (fee: PCIFee, value: RateType) => {
  if (fee === PCIFee.waived) {
    return PCIFeeTypes[fee];
  }
  const isYear = [PCIFee.yearIncludeInsurance, PCIFee.yearExcludeInsurance].includes(fee);
  return `$${value}/${isYear ? 'year' : 'month'} ${PCIFeeTypes[fee].split(' ').slice(1).join(' ')}`;
};
