import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import RichTextEditor from 'react-rte';
import {Collections, Firebase, useNotification} from '../../..';
import {useUserInfo} from '../../../hooks';
import {useComment} from '../hooks/useComment';
import {useCommentOrAttachmentLogic} from '../hooks/useCommentOrAttachmentLogic';
import {useHistoryRecord} from '../hooks/useHistoryRecord';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';
import {useTicketId} from '../hooks/useTicketId';

type Props = {
  handleClose?: () => void;
  commentId?: string;
  comment?: string;
};

export const CommentEditor = ({handleClose, commentId, comment}: Props) => {
  const {addHistoryRecord} = useHistoryRecord();
  const {setValue} = useFormContext();
  const {commentsCollection} = useTicketEditContainer();
  const classes = useStyles();
  const {role: userRole, userDisplayName, uid} = useUserInfo();
  const {ticketId, isTicketIdValid} = useTicketId();
  const {commentAdd} = useComment();
  const [isSaving, setIsSaving] = useState(false);
  const [editorValue, setEditorValue] = useState(
    comment
      ? RichTextEditor.createValueFromString(comment, 'html')
      : RichTextEditor.createEmptyValue()
  );
  const showNotification = useNotification();
  const {handleCommentOrAttachmentChanges} = useCommentOrAttachmentLogic();

  const handleChange = (event: any) => {
    setEditorValue(event);
  };

  const saveComment = useCallback(async (): Promise<boolean> => {
    setIsSaving(true);
    try {
      if (!isTicketIdValid || !uid || !userDisplayName || !userRole || !editorValue) {
        return false;
      }

      if (commentId) {
        await handleCommentOrAttachmentChanges(ticketId, setValue);

        const ticketRef = Firebase.firestore.collection(Collections.tickets).doc(ticketId);
        await ticketRef
          .collection(commentsCollection)
          .doc(commentId)
          .update({
            comment: editorValue.toString('html'),
            updatedAt: Firebase.FieldValue.now(),
          });
      } else {
        const commentId = await commentAdd(editorValue.toString('html'));

        if (commentId === undefined) {
          return false;
        }

        await handleCommentOrAttachmentChanges(ticketId, setValue);
      }

      return true;
    } catch (err) {
      console.error(err);
      showNotification('error', 'An error occurred while saving comment.');
      return false;
    } finally {
      setIsSaving(false);
      handleClose?.();
    }
  }, [
    uid,
    userDisplayName,
    userRole,
    ticketId,
    isTicketIdValid,
    editorValue,
    handleClose,
    commentId,
    commentsCollection,
    addHistoryRecord,
  ]);

  return (
    <div>
      <RichTextEditor
        value={editorValue}
        onChange={handleChange}
        placeholder="Leave a comment"
        className={classes.richTextEditorNote}
        editorStyle={{
          minHeight: '200px',
          fontFamily: 'Rubik, sans-serif',
        }}
      />
      <Box py={2}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="text"
            disabled={isSaving}
            size="medium"
            onClick={() => handleClose?.()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="text"
            size="medium"
            disabled={isSaving}
            onClick={saveComment}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  richTextEditorNote: {
    '& .DraftEditor-root': {height: '200px', minHeight: '200px'},
    '& button[title="Align Left"]': {
      display: 'none',
    },
    '& button[title="Align Center"]': {
      display: 'none',
    },
    '& button[title="Align Right"]': {
      display: 'none',
    },
    '& button[title="Align Justify"]': {
      display: 'none',
    },
    '& button[title="Link"]': {
      display: 'none',
    },
    '& button[title="Remove Link"]': {
      display: 'none',
    },
    '& button[title="Image"]': {
      display: 'none',
    },
  },
}));
