import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {TextField} from '../..';
import {UserRoleName, UserRoleNames} from '../../..';
import {MenuProps} from '../../../helpers';

export type TicketTypeFormData = {
  name: string;
  visibleToRoles: UserRoleName[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
    },
    formControl: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  })
);

export const TicketTypeFormSchema = yup.object({
  name: yup.string().required('Name is required'),
  visibleToRoles: yup
    .array()
    .of(yup.string())
    .required('Visible to roles are required')
    .min(1, 'Visible to roles are required'),
});

type Props = {
  hookForm: UseFormReturn<TicketTypeFormData>;
};

export const TicketTypeForm = ({hookForm}: Props) => {
  const classes = useStyles();
  const [chosenVisibleToRoles, setVisibleToRoles] = useState<string[]>([]);

  const {
    watch,
    formState: {errors},
    control,
    register,
    setValue,
  } = hookForm;

  useEffect(() => {
    register('visibleToRoles');
  }, [register]);

  const handleVisibleToRolesChange = (event: SelectChangeEvent<string[]>) => {
    const visibleToRoles = event.target.value as string[];
    setVisibleToRoles(visibleToRoles);
    setValue('visibleToRoles', visibleToRoles as UserRoleName[], {shouldDirty: true});
  };

  useEffect(() => {
    // set visibleToRoles:
    const curVisibleToRoles: string[] | null =
      (control._defaultValues.visibleToRoles as string[]) || null;
    if (curVisibleToRoles) {
      setVisibleToRoles(curVisibleToRoles);
    }
  }, [control]);

  return (
    <>
      <Grid item xs={12}>
        <TextField name="name" label="Name" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="visibleToRoles-label">Visible To Roles</InputLabel>
          <MuiSelect
            name="visibleToRoles"
            variant="outlined"
            labelId="visibleToRoles-label"
            label="Visible To Roles"
            id="visibleToRoles"
            multiple
            required
            error={Boolean(errors.visibleToRoles)}
            renderValue={selected => (selected as string[]).join(', ')}
            value={chosenVisibleToRoles}
            onChange={handleVisibleToRolesChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {UserRoleNames.map(x => (
              <MenuItem key={`${x}`} value={x}>
                <MuiCheckbox checked={chosenVisibleToRoles.indexOf(x) > -1} color="primary" />
                <ListItemText primary={x} />
              </MenuItem>
            ))}
          </MuiSelect>
          {errors.visibleToRoles?.message && (
            <FormHelperText error={true}>{errors.visibleToRoles?.message}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </>
  );
};
