import {Divider, Switch, Theme, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '1.2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
    },
  },
}));

interface SectionTitleProps {
  text: string | React.Component | any;
}

export const SectionTitle = ({text}: SectionTitleProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="caption" gutterBottom>
        {text}
      </Typography>
      <Divider />
    </div>
  );
};

interface SectionTitleSwitchProps extends SectionTitleProps {
  isChecked: boolean;
  handleSwitchChange: (value: boolean) => void;
}

export const SectionTitleSwitch = ({
  text,
  isChecked,
  handleSwitchChange,
}: SectionTitleSwitchProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="caption" gutterBottom>
        {text}
      </Typography>
      <Switch value={isChecked} onChange={e => handleSwitchChange(e.target.checked)} />
      <Divider />
    </div>
  );
};
